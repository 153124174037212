
<template>
    <v-app style="background-color: #ddd;">
        <v-content class="content">
            <v-card width="300" height="340">
                <v-card-title class="headline justify-center">
                    Pedido Confirmado!
                </v-card-title>
                <v-card-text class=text-center>
                    <v-icon color="green" style="font-size: 80px;">
                        mdi-checkbox-marked-circle-outline
                    </v-icon>
                    <br>
                    <br>
                    <b>Seu pedido já está na<br>fila de preparo.</b>
                    <br>
                    Envie o pedido no WhatsApp e acompanhe o andamento!
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="green"
                        class="white--text"
                        @click="sendWhatsapp"
                        :loading="loading"
                        block
                    >
                        <v-icon>mdi-whatsapp</v-icon>&nbsp;
                        Enviar no WhatsApp
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-content>
    </v-app>
</template>

<script>
export default {
    name: 'RedirectToWhatsapp',

    data: () => ({
        text: '',
        phone: '',
        loading: false,
    }),

    async mounted() {
        await this.getPedido();
    },

    methods: {
        async getPedido() {
            const { emp, ped } = this.$route.params;

            this.loading = true;

            await this.$http.get(`pedido/${emp}/${ped}`).then(resp => {
                const { phone, text } = resp.data.data;

                this.phone = phone;
                this.text = window.encodeURIComponent(text);
                this.loading = false;
            });
        },

        async sendWhatsapp() {
            window.open(`whatsapp://send?phone=${this.phone}&text=${this.text}`);
        },
    },
}
</script>

<style scoped>
.content {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin: auto;
}
</style>
